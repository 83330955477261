import React, { FC, useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  PresenceTransition,
  ScrollView,
  Spinner,
  Text,
  useColorMode,
  VStack,
} from "native-base"
import { useStores } from "../../models"
import { G, Path } from "react-native-svg"
import { NewMessage } from "./new-message"
import { MessageCard } from "./message-card"
import { SharedElement } from "react-navigation-shared-element"

export const TopicScreen: FC<StackScreenProps<NavigatorParamList, "topic">> = observer(
  function TopicScreen({ route, navigation }) {
    const { topicStore } = useStores()
    const { colorMode } = useColorMode()
    const { id, name } = route.params
    const topic = topicStore.topics.find((topic) => topic.id === id)

    useEffect(() => {
      topicStore.fetch(id)
    }, [])

    useEffect(() => {
      // TODO: better mecanism
    }, [topicStore.status])

    const scrollViewRef: React.MutableRefObject<typeof ScrollView> = useRef()
    if (topic !== undefined) {
      useEffect(() => {
        if (scrollViewRef.current) {
          scrollViewRef.current.scrollToEnd()
        }
      }, [topic.messages.length])
    }

    return (
      <Box
        safeArea
        flex={1}
        w="100%"
        height="100%"
        mx="auto"
        _light={{ backgroundColor: "warmGray.50" }}
        _dark={{ backgroundColor: "warmGray.800" }}
      >
        <HStack p={2} alignItems="center" justifyContent="space-between" shadow={3} zIndex={10}>
          <Button
            onPress={() => navigation.goBack()}
            borderRadius={100}
            variant="ghost"
            p="2"
            colorScheme="secondary"
          >
            <Icon viewBox="0 0 24 24" size="5">
              <G strokeWidth={1.5} stroke={colorMode === "light" ? "#000d" : "#fffd"} fill="none">
                <Path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </G>
            </Icon>
          </Button>
          <SharedElement id={`item.${id}.name`}>
            <Heading fontSize="16">{name}</Heading>
          </SharedElement>
          <Button
            onPress={() => topicStore.fetch(topic.id)}
            borderRadius={100}
            variant="ghost"
            p="2"
            colorScheme="secondary"
          >
            <Icon viewBox="0 0 24 24" size="5">
              <G strokeWidth={1.5} stroke={colorMode === "light" ? "#000d" : "#fffd"} fill="none">
                <Path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </G>
            </Icon>
          </Button>
        </HStack>
        {topic !== undefined && topic.messages.length > 0 && (
          <ScrollView ref={scrollViewRef} h={100}>
            <PresenceTransition
              visible={true}
              initial={{
                opacity: 0,
                translateY: 64,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                transition: {
                  duration: 350,
                },
              }}
            >
              <VStack p={2} flexGrow={1} justifyContent="flex-end">
                {topic.messages.map((message) => {
                  return (
                    <MessageCard
                      key={message.id}
                      message={message}
                      topic={topic}
                      navigation={navigation}
                    />
                  )
                })}
                {topic.delta > 0 && (
                  <Text textAlign="center" p={8} fontStyle="italic" opacity={0.5}>
                    You are {topic.delta} message{topic.delta === 1 ? "" : "s"} ahead,{"\n"}
                    your friend will not see {topic.delta === 1 ? "it" : "them"} until they send
                    more messages.
                  </Text>
                )}
                {topic.delta < 0 && (
                  <Text textAlign="center" p={8} fontStyle="italic" opacity={0.5}>
                    You are {-topic.delta} message{topic.delta === -1 ? "" : "s"} behind,{"\n"}
                    send more messages to view {topic.delta === -1 ? "it" : "them"}.
                  </Text>
                )}
              </VStack>
            </PresenceTransition>
          </ScrollView>
        )}

        {topic === undefined && (
          <VStack flexGrow={1} justifyContent="center">
            <Spinner accessibilityLabel="Loading" />
          </VStack>
        )}
        {topic !== undefined && topic.messages.length === 0 && (
          <VStack flexGrow={1} justifyContent="center" alignItems="center">
            <Heading opacity={0.4}>ʘ‿ʘ</Heading>
            <Text opacity={0.4}>0 message for now...</Text>
            <Text textAlign="center" mt={8} p={8} fontStyle="italic" opacity={0.5}>
              You are {-topic.delta} message{topic.delta === -1 ? "" : "s"} behind,{"\n"}
              send more messages to view {topic.delta === -1 ? "it" : "them"}.
            </Text>
          </VStack>
        )}
        <NewMessage topic={topic} />
      </Box>
    )
  },
)
