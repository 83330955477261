import CryptoJS from "crypto-js"
import { cryptico, RSAKey } from "../services/cryptico"

/**
 * Repeatably generatable RSA keys.
 *
 * @param username username
 * @param password user password
 * @returns RSA keys
 */
export const getRSAKeys = (username: string, password: string) => {
  const private_key = cryptico.generateRSAKey(
    CryptoJS.SHA512(username + password).toString(CryptoJS.enc.Hex),
    1024,
  )
  const public_key = cryptico.publicKeyString(private_key)
  return { private_key, public_key }
}

/**
 * Encrypt a string for multiple users using GPG (GNU Privacy Guard).
 *
 * @param users users list
 * @param rawString raw string to encrypt
 * @returns encrypted string and keys
 */
export const encryptWithSharedKey = (
  users: { id: number; username: string; public_key: string }[],
  rawString: string,
) => {
  const sharedKey = CryptoJS.lib.WordArray.random(128 / 8).toString()
  const keys: Map<number, string> = new Map()

  users.forEach((user) => (keys[user.id] = encrypt(sharedKey, user.public_key)))
  const encryptedString = CryptoJS.AES.encrypt(rawString, sharedKey).toString()

  return { encryptedString, keys }
}

/**
 * Encrypt a string.
 *
 * @param rawString raw string to encrypt
 * @param publicKey user public key
 * @returns encrypted string
 */
export const encrypt = (rawString: string, publicKey: string) => {
  const encryptResult = cryptico.encrypt(rawString, publicKey)

  if (encryptResult.status === "success") {
    return encryptResult.cipher
  } else {
    console.error("🔑️ Encryption failed", encryptResult.status)
    throw new Error(encryptResult.status)
  }
}

/**
 * Decrypt a string that has been encrypted for multiple users.
 *
 * @param encryptedString string to decrypt
 * @param encryptedKey user encrypted assymetric key
 * @param privateKey user private key
 * @returns decrypted string
 */
export const decryptWithEncryptedKey = (encryptedString: string, encryptedKey: string, privateKey: string) => {
  const rsaKey = RSAKey.parse(privateKey)
  const decryptResult = cryptico.decrypt(encryptedKey, rsaKey)

  if (decryptResult.status === "success") {
    return CryptoJS.enc.Utf8.stringify(
      CryptoJS.AES.decrypt(encryptedString, decryptResult.plaintext),
    )
  } else {
    console.error("🔑️ Encryption failed", decryptResult.status)
    throw new Error(decryptResult.status)
  }
}

/**
 * Decrypt a string.
 *
 * @param encryptedString string to decrypt
 * @param privateKey user private key
 * @returns decrypted string
 */
export const decrypt = (encryptedString: string, privateKey: string) => {
  const rsaKey = RSAKey.parse(privateKey)
  const decryptResult = cryptico.decrypt(encryptedString, rsaKey)

  if (decryptResult.status === "success") {
    return decryptResult.plaintext
  } else {
    console.error("🔑️ Encryption failed", decryptResult.status)
    throw new Error(decryptResult.status)
  }
}
