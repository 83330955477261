import { Box, HStack, Icon, Pressable, Text, useColorMode, View } from "native-base"
import React, { useState } from "react"
import { useStores } from "../../models"
import { Message, Topic } from "../../models/topic-store/topic-store"
import Markdown from "../../services/markdown"
import { Linking } from "react-native"
import { SharedElement } from "react-navigation-shared-element"
import { G, Path } from "react-native-svg"
import { color } from "../../theme"

export const MessageCard = ({
  message,
  topic,
  navigation,
}: {
  message: Message
  topic?: Topic
  navigation?: any
}) => {
  const { userStore, topicStore } = useStores()
  const { colorMode } = useColorMode()
  const [showDate, setShowDate] = useState(false)

  return (
    <Pressable
      w="100%"
      backgroundColor="gray.100"
      _light={{ backgroundColor: "warmGray.100" }}
      _dark={{ backgroundColor: "warmGray.800" }}
      py={1}
      onPress={() => {
        if (navigation) {
          navigation.navigate("thread", {
            id: message.topic_id,
            name: topic.name,
            message: message.id,
          })
        } else {
          setShowDate(!showDate)
        }
      }}
    >
      {({ isHovered, isFocused, isPressed }) => {
        const userStore = topicStore.environment.api.userStore

        return (
          <SharedElement id={`item.${message.id}.message`}>
            <View
              ml={message.user_id === userStore.id ? 6 : 0}
              mr={message.user_id !== userStore.id ? 6 : 0}
            >
              <Box
                mt={1}
                p={2}
                pb={2}
                roundedTop={4}
                roundedBottom={message.answers_count > 0 ? 0 : 4}
                backgroundColor={
                  message.user_id === userStore.id
                    ? colorMode === "light"
                      ? "blue.100"
                      : "#52402d"
                    : colorMode === "light"
                    ? "gray.50"
                    : "warmGray.700"
                }
                shadow={1}
                borderWidth={message.new ? 2 : 0}
                borderColor={colorMode === "light" ? "blue.200" : "warmGray.700"}
              >
                <Markdown
                  styles={
                    colorMode === "light"
                      ? {}
                      : {
                          text: {
                            color: "white",
                            opacity: 0.8,
                          },
                          autolink: {
                            color: "#d97706",
                          },
                        }
                  }
                  onLink={(url) => Linking.openURL(url)}
                  enableLightBox={true}
                  navigator={null}
                >
                  {message.getTextMessage()}
                </Markdown>
                {message.read_at !== null && message.user_id === userStore.id && (
                  <Icon ml="auto" viewBox="0 0 24 24" size="3">
                    <G strokeWidth={2} stroke="#0008" fill="none">
                      <Path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </G>
                  </Icon>
                )}
              </Box>
              {message.answers_count > 0 && (
                <HStack
                  px={2}
                  py={1}
                  roundedBottom={4}
                  backgroundColor={
                    message.user_id === userStore.id
                      ? colorMode === "light"
                        ? "blue.100"
                        : "#52402d"
                      : colorMode === "light"
                      ? "gray.50"
                      : "warmGray.700"
                  }
                  shadow={1}
                  borderTopWidth={message.unread_answers_count > 0 ? 2 : 1}
                  borderColor={
                    message.user_id === userStore.id
                      ? colorMode === "light"
                        ? "blue.200"
                        : "#6C5A47"
                      : colorMode === "light"
                      ? "gray.50"
                      : "warmGray.600"
                  }
                >
                  <Text opacity={0.5}>{message.answers_count} answers</Text>
                  {message.unread_answers_count > 0 && (
                    <Text opacity={0.9} fontWeight={600} ml={2}>
                      with {message.unread_answers_count} unread
                    </Text>
                  )}
                </HStack>
              )}
              {showDate && (
                <Text
                  px={1}
                  mt={1}
                  fontSize={8}
                  opacity={0.7}
                  textAlign={message.user_id === userStore.id ? "right" : "left"}
                >
                  #{message.id} {new Date(message.created_at).toLocaleString()}
                </Text>
              )}
            </View>
          </SharedElement>
        )
      }}
    </Pressable>
  )
}

const simpleStyle = {
  // text: {
  //   color: "blue",
  // },
}
