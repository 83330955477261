import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { LoginResult, RegisterResult, UserPublicKeyResult, UserResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { encrypt, getRSAKeys } from "../../utils/encryption"

export class UserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  /**
   * Register a user.
   *
   * @param rawName uncrypted name
   * @param username username
   * @param password user password
   * @returns reponse with token and keys if registration was successful
   */
  async register(rawName: string, username: string, password: string): Promise<RegisterResult> {
    const { private_key, public_key } = getRSAKeys(username, password)

    try {
      const encryptedName = encrypt(rawName, public_key)

      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post("/api/users/create", {
        name: encryptedName,
        username,
        password,
        public_key,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", ...response.data, private_key }
    } catch (e) {
      console.error("🔑️ Encryption failed", e)
      return { kind: "rejected" }
    }
  }

  /**
   * Login to fetch a api_key token.
   *
   * @param username user username
   * @param password user password
   * @returns reponse with token if authentication was successful
   */
  async login(username: string, password: string): Promise<LoginResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post("/api/users/authenticate", {
        username,
        password,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      const { private_key, public_key } = getRSAKeys(username, password)

      return { kind: "ok", ...response.data, private_key, public_key }
    } catch (e) {
      console.error(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  /**
   * Get the public key for a specific user.
   *
   * @param id user id
   * @returns response with the user public key
   */
  async getPublicKey(id: number): Promise<UserPublicKeyResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        "/api/users/" + id + "/public-key",
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", public_key: response.data }
    } catch (e) {
      console.error(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  /**
   * Get the user for a given username.
   *
   * @param username username
   * @returns response with the user
   */
  async getByUsername(username: string): Promise<UserResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        "/api/users/search/username/" + username,
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", ...response.data }
    } catch (e) {
      console.error(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
