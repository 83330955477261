import { HStack, Text, View } from "native-base"
import React, { useEffect, useState } from "react"
import { delay } from "../../utils/delay"

export const Matrix = ({ text, color, timeout = 20 }) => {
  const [data, setData] = useState(
    Array.from({ length: text.length }, () => Math.floor(Math.random() * 60)),
  )
  const getText = () => {
    let result = ""
    for (let i = 0; i < text.length; i++) {
      if (data[i] > 0) {
        result += String.fromCharCode(33 + Math.floor(Math.random() * 84))
      } else {
        result += text[i]
      }
    }
    return result
  }

  useEffect(() => {
    const updateData = async () => {
      const newData = data.map((n) => Math.max(0, n - 1))
      if (JSON.stringify(newData) !== JSON.stringify(data)) {
        await delay(timeout)
        setData(newData)
      }
    }
    updateData()
  }, [data])

  return (
    <View style={{ flexDirection: "row" }}>
      <HStack justifyContent="center" style={{ flex: 1, flexWrap: "wrap" }}>
        {Array.from(getText()).map((letter, index) => {
          return (
            <Text
              key={index}
              size={4}
              color={color + Math.floor(15 - (data[index] / 60) * 15).toString(16)}
              style={{ fontFamily: "monospace" }}
            >
              {letter}
            </Text>
          )
        })}
      </HStack>
    </View>
    // <Text {...props} style={{ fontFamily: "monospace" }}>
    //   {getText()}
    // </Text>
  )
}
