import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { StackScreenProps } from "@react-navigation/stack"
import { navigate, NavigatorParamList } from "../../navigators"
import { Button, Center, FormControl, HStack, Icon, Input, Text, View, VStack } from "native-base"
import { color } from "../../theme/color"
import { useStores } from "../../models"
import { G, Path } from "react-native-svg"
import { Controller, useForm } from "react-hook-form"
import { NativeSyntheticEvent, TextInputKeyPressEventData } from "react-native"

export const CreateTopicScreen: FC<StackScreenProps<NavigatorParamList, "topic/create">> = observer(
  function CreateTopicScreen({ navigation }) {
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm()

    const { topicStore, userStore } = useStores()

    const onSubmit = async (values) => {
      userStore
        .getByUsername(values.username)
        .then(
          (getByUsernameResult) =>
            userStore
              .getPublicKey(getByUsernameResult.id)
              .then((getPublicKeyResult) =>
                navigate("topic/validate", {
                  users: [
                    {
                      id: parseInt(getByUsernameResult.id),
                      username: values.username,
                      public_key: getPublicKeyResult,
                    },
                    {
                      id: userStore.id,
                      username: userStore.username,
                      public_key: userStore.public_key,
                    },
                  ],
                  name: values.name,
                }),
              )
              .catch((reason) => console.error(reason)), // TODO display info to user with toast
        )
        .catch((reason) => console.error(reason)) // TODO display info to user with toast
    }

    const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      event.nativeEvent.key === "Enter" && handleSubmit(onSubmit)()
    }

    useEffect(() => {
      if (topicStore.status === "idle") {
        navigation.goBack()
      }
    }, [topicStore.status])

    return (
      <View flex={1} p="3" w="100%" mx="auto">
        <HStack direction="row-reverse" ml="auto">
          <Button
            onPress={() => topicStore.setStatus("idle")}
            borderRadius={100}
            variant="ghost"
            p="2"
            colorScheme="secondary"
          >
            <Icon viewBox="0 0 24 24" size="6" marginLeft="auto">
              <G strokeWidth={1.5} stroke="#000d" fill="none">
                <Path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </G>
            </Icon>
          </Button>
        </HStack>
        <Center flexGrow={1} w="100%" maxW={420} mx="auto" mt="5">
          <VStack space={8} w="100%">
            <FormControl isRequired isInvalid={"username" in errors}>
              <FormControl.Label>Username</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onBlur={onBlur}
                    placeholder="bright-blue-turtle"
                    onKeyPress={handleKeyPress}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="username"
                rules={{ required: "Username is required" }}
                defaultValue=""
              />
              <FormControl.ErrorMessage>{errors.username?.message}</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={"name" in errors}>
              <FormControl.Label>Topic name</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onBlur={onBlur}
                    placeholder="💡 Ideas"
                    onKeyPress={handleKeyPress}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="name"
                rules={{ required: "Topic name is required" }}
                defaultValue=""
              />
              <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>
            </FormControl>

            <Button
              onPress={(_) => handleSubmit(onSubmit)()}
              mt="2"
              isLoading={userStore.status === "loading"}
              isLoadingText="Creating..."
            >
              Create
            </Button>
            {topicStore.status === "error" && (
              <Text color={color.error}>Creation failed, check the username</Text>
            )}
          </VStack>
        </Center>
      </View>
    )
  },
)
