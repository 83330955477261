import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Button, Center, Heading, HStack, Icon, Text, VStack } from "native-base"
import { useStores } from "../../models"
import { G, Path } from "react-native-svg"
import { decrypt } from "../../utils/encryption"

export const InfoScreen: FC<StackScreenProps<NavigatorParamList, "info">> = observer(
  function InfoScreen({ navigation }) {
    const { userStore } = useStores()

    return (
      <Center safeArea p="2" py="8" w="100%" mx="auto">
        <VStack w="100%" maxW="450" space={3} mt="5">
          <HStack direction="row-reverse">
            <Button
              onPress={() => navigation.pop()}
              borderRadius={100}
              variant="ghost"
              p="2"
              colorScheme="secondary"
            >
              <Icon viewBox="0 0 24 24" size="6" marginLeft="auto">
                <G strokeWidth={1.5} stroke="#000d" fill="none">
                  <Path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </G>
              </Icon>
            </Button>
          </HStack>
          <Heading mt="1" mb="1" fontWeight="medium" size="xs">
            Information
          </Heading>
          <Text color={"gray.700"}>id: {userStore.id}</Text>
          <Text color={"gray.700"}>name: {decrypt(userStore.name, userStore.private_key)}</Text>
          <Text color={"gray.700"}>username: {userStore.username}</Text>
          <Text color={"gray.700"}>status: {userStore.status}</Text>
          <Text color={"gray.700"}>token: {userStore.token}</Text>
          <Text color={"gray.700"}>public_key: {userStore.public_key}</Text>
        </VStack>
      </Center>
    )
  },
)
