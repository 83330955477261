import React from "react"

import { Image, Text, View } from "react-native"

import Lightbox from "react-native-lightbox-v2"

import SimpleMarkdown from "simple-markdown"

export const Rules = (styles, opts = {}) => {
  const enableLightBox = opts.enableLightBox || false
  // const enableLightBox = true
  const navigator = opts.navigator

  const pressHandler = function (target) {
    if (opts.onLink) {
      opts.onLink(target).catch(function (error) {
        console.log("There has been a problem with this action. " + error.message)
        throw error
      })
    }
  }

  return {
    em: {
      ...SimpleMarkdown.defaultRules.em,
      react: function (node, output, { ...state }) {
        state.withinText = true
        state.style = { ...state.style, ...styles.em }
        return React.createElement(
          Text,
          {
            key: state.key,
            style: styles.em,
          },
          output(node.content, state),
        )
      },
    },

    heading: {
      ...SimpleMarkdown.defaultRules.heading,
      react: function (node, output, { ...state }) {
        state.withinText = true
        state.withinHeading = true

        state.style = { ...state.style, ...styles["heading" + node.level] }

        const ret = React.createElement(
          Text,
          {
            key: state.key,
            style: state.style,
          },
          output(node.content, state),
        )
        return ret
      },
    },

    image: {
      ...SimpleMarkdown.defaultRules.image,
      react: function (node, output, { ...state }) {
        const imageParam = opts.imageParam ? opts.imageParam : ""
        const target = node.target + imageParam
        const image = React.createElement(Image, {
          key: state.key,
          // resizeMode: 'contain',
          source: { uri: target },
          style: styles.image,
        })
        if (enableLightBox) {
          return React.createElement(
            Lightbox,
            {
              activeProps: styles.imageBox,
              key: state.key,
              navigator,
              onOpen: opts.onImageOpen,
              onClose: opts.onImageClose,
              springConfig: {
                tension: 900000,
                friction: 900000,
              },
            },
            image,
          )
        }
        return image
      },
    },

    link: {
      ...SimpleMarkdown.defaultRules.link,
      react: function (node, output, { ...state }) {
        state.withinLink = true
        const _pressHandler = () => {
          pressHandler(node.target)
        }
        const link = React.createElement(
          Text,
          {
            key: state.key,
            style: styles.autolink,
            onPress: _pressHandler,
          },
          output(node.content, state),
        )
        state.withinLink = false
        return link
      },
    },

    newline: {
      ...SimpleMarkdown.defaultRules.newline,
      react: function (node, output, { ...state }) {
        return React.createElement(
          Text,
          {
            key: state.key,
            style: styles.newline,
          },
          "\n",
        )
      },
    },

    paragraph: {
      ...SimpleMarkdown.defaultRules.paragraph,
      react: function (node, output, { ...state }) {
        let paragraphStyle = styles.paragraph
        // Allow image to drop in next line within the paragraph
        if (node.content.some((element) => element.type === "image")) {
          state.withinParagraphWithImage = true
          const paragraph = React.createElement(
            View,
            {
              key: state.key,
              style: styles.paragraphWithImage,
            },
            output(node.content, state),
          )
          state.withinParagraphWithImage = false
          return paragraph
        }
        if (state.withinList) {
          paragraphStyle = { ...paragraphStyle, ...styles.noMargin }
        }
        return React.createElement(
          Text,
          {
            key: state.key,
            style: paragraphStyle,
          },
          output(node.content, state),
        )
      },
    },

    strong: {
      ...SimpleMarkdown.defaultRules.strong,
      react: function (node, output, { ...state }) {
        state.withinText = true
        state.style = { ...state.style, ...styles.strong }
        return React.createElement(
          Text,
          {
            key: state.key,
            style: state.style,
          },
          output(node.content, state),
        )
      },
    },

    text: {
      ...SimpleMarkdown.defaultRules.text,
      react: function (node, output, { ...state }) {
        let textStyle = { ...styles.text, ...state.style }

        if (state.withinLink) {
          textStyle = { ...styles.text, ...styles.autolink }
        }

        if (state.withinQuote) {
          textStyle = { ...styles.text, ...styles.blockQuoteText }
        }

        return React.createElement(
          Text,
          {
            key: state.key,
            style: textStyle,
          },
          node.content,
        )
      },
    },
    // autolink: {
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     const _pressHandler = () => {
    //       pressHandler(node.target);
    //     };
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.autolink,
    //       onPress: _pressHandler,
    //     }, output(node.content, state));
    //   },
    // },
    // blockQuote: {
    //   react: function (node, output, {...state}) {
    //     state.withinQuote = true;

    //     const img = React.createElement(View, {
    //       key: state.key - state.key,
    //       style: [styles.blockQuoteSectionBar, styles.blockQuoteBar],
    //     });

    //     let blockQuote = React.createElement(Text, {
    //       key: state.key,
    //       style: styles.blockQuoteText,
    //     }, output(node.content, state));

    //     return React.createElement(View, {
    //       key: state.key,
    //       style: [styles.blockQuoteSection, styles.blockQuoteText],
    //     }, [img, blockQuote]);

    //   },
    // },
    // br: {
    //   react: function (node, output, {...state}) {
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.br,
    //     }, '\n\n');
    //   },
    // },
    // codeBlock: {
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.codeBlock,
    //     }, node.content);
    //   },
    // },
    // del: {
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.del,
    //     }, output(node.content, state));
    //   },
    // },
    // em: {
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     state.style = {
    //       ...(state.style || {}),
    //       ...styles.em
    //     };
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.em,
    //     }, output(node.content, state));
    //   },
    // },
    // hr: {
    //   react: function (node, output, {...state}) {
    //     return React.createElement(View, {key: state.key, style: styles.hr});
    //   },
    // },
    // image: {
    //   react: function (node, output, {...state}) {
    //     var imageParam = opts.imageParam ? opts.imageParam : '';
    //     var target = node.target + imageParam;
    //     var image = React.createElement(Image, {
    //       key: state.key,
    //       // resizeMode: 'contain',
    //       source: {uri: target},
    //       style: styles.image,
    //     });
    //     if (enableLightBox) {
    //       return React.createElement(Lightbox, {
    //         activeProps: styles.imageBox,
    //         key: state.key,
    //         navigator,
    //         onOpen: opts.onImageOpen,
    //         onClose: opts.onImageClose,
    //       }, image);
    //     }
    //     return image;
    //   },
    // },
    // inlineCode: {
    //   parse: parseCaptureInline,
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.inlineCode,
    //     }, output(node.content, state));
    //   },
    // },
    // link: {
    //   ...SimpleMarkdown.defaultRules.link,
    //   // match: SimpleMarkdown.inlineRegex(new RegExp(
    //   //   '^\\[(' + LINK_INSIDE + ')\\]\\(' + LINK_HREF_AND_TITLE + '\\)'
    //   // )),
    //   react: function (node, output, {...state}) {
    //     state.withinLink = true;
    //     const _pressHandler = () => {
    //       pressHandler(node.target);
    //     };
    //     const link = React.createElement(Text, {
    //       key: state.key,
    //       style: styles.autolink,
    //       onPress: _pressHandler,
    //     }, output(node.content, state));
    //     state.withinLink = false;
    //     return link;
    //   },
    // },
    // list: {
    //   react: function (node, output, {...state}) {
    //     var numberIndex = 1;
    //     var items = node.items.map(function (item, i) {
    //       var bullet;
    //       state.withinList = false;

    //       if (node.ordered) {
    //         bullet = React.createElement(Text, {key: 0, style: styles.listItemNumber}, (numberIndex) + '. ');
    //       }
    //       else {
    //         bullet = React.createElement(Text, {key: 0, style: styles.listItemBullet}, '\u2022 ');
    //       }

    //       if (item.length > 1) {
    //         if (item[1].type == 'list') {
    //           state.withinList = true;
    //         }
    //       }

    //       var content = output(item, state);
    //       var listItem;
    //       if (['text', 'paragraph', 'strong'].includes(item[0]?.type) && state.withinList == false) {
    //         state.withinList = true;
    //         listItem = React.createElement(Text, {
    //           style: [styles.listItemText, {marginBottom: 0}],
    //           key: 1,
    //         }, content);
    //       } else {
    //         listItem = React.createElement(View, {
    //           style: styles.listItemText,
    //           key: 1,
    //         }, content);
    //       }
    //       state.withinList = false;
    //       numberIndex++;

    //       return React.createElement(View, {
    //         key: i,
    //         style: styles.listRow,
    //       }, [bullet, listItem]);
    //     });

    //     return React.createElement(View, {key: state.key, style: styles.list}, items);
    //   },
    // },
    // sublist: {
    //   react: function (node, output, {...state}) {

    //     var items = node.items.map(function (item, i) {
    //       var bullet;
    //       if (node.ordered) {
    //         bullet = React.createElement(Text, {key: 0, style: styles.listItemNumber}, (i + 1) + '. ');
    //       }
    //       else {
    //         bullet = React.createElement(Text, {key: 0, style: styles.listItemBullet}, '\u2022 ');
    //       }

    //       var content = output(item, state);
    //       var listItem;
    //       state.withinList = true;
    //       if (['text', 'paragraph', 'strong'].includes(item[0]?.type)) {
    //         listItem = React.createElement(Text, {
    //           style: styles.listItemText,
    //           key: 1,
    //         }, content);
    //       } else {
    //         listItem = React.createElement(View, {
    //           style: styles.listItem,
    //           key: 1,
    //         }, content);
    //       }
    //       state.withinList = false;
    //       return React.createElement(View, {
    //         key: i,
    //         style: styles.listRow,
    //       }, [bullet, listItem]);
    //     });

    //     return React.createElement(View, {key: state.key, style: styles.sublist}, items);
    //   },
    // },
    // mailto: {
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.mailto,
    //       onPress: () => {},
    //     }, output(node.content, state));
    //   },
    // },
    // newline: {
    //   react: function (node, output, {...state}) {
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.newline,
    //     }, '\n');
    //   },
    // },
    // paragraph: {
    //   react: function (node, output, {...state}) {
    //     let paragraphStyle = styles.paragraph;
    //     // Allow image to drop in next line within the paragraph
    //     if (node.content.some((element) => element.type === 'image')) {
    //       state.withinParagraphWithImage = true;
    //       var paragraph = React.createElement(View, {
    //         key: state.key,
    //         style: styles.paragraphWithImage,
    //       }, output(node.content, state));
    //       state.withinParagraphWithImage = false;
    //       return paragraph;
    //     }
    //     if (state.withinList) {
    //       paragraphStyle = [paragraphStyle, styles.noMargin];
    //     }
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: paragraphStyle,
    //     }, output(node.content, state));
    //   },
    // },
    // strong: {
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     state.style = {
    //       ...(state.style || {}),
    //       ...styles.strong
    //     };
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: state.style,
    //     }, output(node.content, state));
    //   },
    // },
    // table: {
    //   react: function (node, output, {...state}) {
    //     var headers = node.header.map(function (content, i) {
    //       return React.createElement(Text, {
    //         key: i,
    //         style: styles.tableHeaderCell,
    //       }, output(content, state));
    //     });

    //     var header = React.createElement(View, {key: -1, style: styles.tableHeader}, headers);

    //     var rows = node.cells.map(function (row, r) {
    //       var cells = row.map(function (content, c) {
    //         return React.createElement(View, {
    //           key: c,
    //           style: styles.tableRowCell,
    //         }, output(content, state));
    //       });
    //       var rowStyles = [styles.tableRow];
    //       if (node.cells.length - 1 == r) {
    //         rowStyles.push(styles.tableRowLast);
    //       }
    //       return React.createElement(View, {key: r, style: rowStyles}, cells);
    //     });

    //     return React.createElement(View, {key: state.key, style: styles.table}, [header, rows]);
    //   },
    // },
    // text: {
    //   ...SimpleMarkdown.defaultRules.text,
    //   react: function (node, output, {...state}) {
    //     let textStyle = {
    //       ...styles.text,
    //       ...(state.style || {})
    //     };

    //     if (state.withinLink) {
    //       textStyle = [styles.text, styles.autolink];
    //     }

    //     if (state.withinQuote) {
    //       textStyle = [styles.text, styles.blockQuoteText];
    //     }

    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: textStyle,
    //     }, node.content);
    //   },
    // },
    // u: { // u will to the same as strong, to avoid the View nested inside text problem
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     state.style = {
    //       ...(state.style || {}),
    //       ...styles.u
    //     };
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.strong,
    //     }, output(node.content, state));
    //   },
    // },
    // url: {
    //   react: function (node, output, {...state}) {
    //     state.withinText = true;
    //     const _pressHandler = () => {
    //       pressHandler(node.target);
    //     };
    //     return React.createElement(Text, {
    //       key: state.key,
    //       style: styles.autolink,
    //       onPress: _pressHandler,
    //     }, output(node.content, state));
    //   },
    // },
  }
}
