import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { TopicApi } from "../../services/api/topic-api"
import { withEnvironment } from "../extensions/with-environment"
import { User } from "../user-store/user"
import { decryptWithEncryptedKey, encryptWithSharedKey } from "../../utils/encryption"

export const Message = types
  .model("Message")
  .props({
    id: types.number,
    topic_id: types.number,
    user_id: types.number,
    content: types.string,
    created_at: types.string,
    read_at: types.maybeNull(types.string),
    parent_id: types.maybeNull(types.number),
    keys: types.map(types.string),
    new: types.optional(types.boolean, false),
    answers_count: types.maybeNull(types.number),
    unread_answers_count: types.maybeNull(types.number),
    answers: types.optional(types.array(types.late(() => Message)), []),
  })
  .extend(withEnvironment)
  .views((self) => ({
    getTextMessage(): string {
      const userStore = self.environment.api.userStore
      const encryptedKey = self.keys.get(userStore.id + "")
      return decryptWithEncryptedKey(self.content, encryptedKey, userStore.private_key)
    },
  }))

type MessageType = Instance<typeof Message>
export interface Message extends MessageType {}

export const Topic = types
  .model("Topic")
  .props({
    id: types.number,
    name: types.string,
    users: types.optional(types.array(User), []),
    messages: types.optional(types.array(Message), []),
    delta: types.maybe(types.number),
    total: types.maybe(types.number),
    unread: types.maybe(types.number),
  })
  .actions((self) => ({
    update(data) {
      self.users = data.users
      self.messages = data.messages
      self.delta = data.delta
    },
  }))

type TopicType = Instance<typeof Topic>
export interface Topic extends TopicType {}

export const TopicsModel = types.model("Topics").props({
  topics: types.array(Topic),
})

type TopicsType = Instance<typeof TopicsModel>
export interface Topics extends TopicsType {}
export const createTopicsDefaultModel = () => types.optional(TopicsModel, {})

export const TopicsStoreModel = types
  .model("TopicStore")
  .props({
    topics: types.optional(types.array(Topic), []),
    status: types.optional(
      types.enumeration("Status", [
        "idle",
        "sending",
        "error",
        "loading",
        "processing",
        "loading-answers",
        "creating",
      ]),
      "idle",
    ),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setStatus: (status: string) => {
      self.status = status
    },
  }))
  .actions((self) => ({
    info: flow(function* (id: number) {
      const topicApi = new TopicApi(self.environment.api)
      const result = yield topicApi.info(id)

      if (result.kind === "ok") {
        const topic = self.topics.find((topic) => topic.id == id)
        topic.delta = result.delta
        topic.total = result.total
        topic.unread = result.unread
      } else {
        __DEV__ && console.tron.log(result.kind)
      }
    }),
  }))
  .actions((self) => ({
    load: flow(function* () {
      self.status = "loading"
      const topicApi = new TopicApi(self.environment.api)
      const result = yield topicApi.list()

      if (result.kind === "ok") {
        self.status = "idle"
        const topics = result.topics
        topics.forEach((topic) => {
          const userStore = self.environment.api.userStore
          const encryptedKey = topic.keys[userStore.id]
          topic.name = decryptWithEncryptedKey(topic.name, encryptedKey, userStore.private_key)
        })
        self.topics.replace(topics)
        result.topics.forEach((topic) => {
          self.info(topic.id)
        })
      } else {
        self.status = "error"
        __DEV__ && console.tron.log(result.kind)
      }
    }),
  }))
  .actions((self) => ({
    fetch: flow(function* (id: number) {
      const topicApi = new TopicApi(self.environment.api)
      const result = yield topicApi.fetch(id)

      if (result.kind === "ok") {
        const topic = self.topics.find((topic) => topic.id == id)
        const { messages, users, delta } = result
        if (topic.messages.length > 0) {
          const ids = new Set(topic.messages.map((message) => message.id))
          messages.forEach((message) => {
            if (!ids.has(message.id)) {
              message["new"] = true
            }
          })
        }
        topic.update({ messages, users, delta })
      } else {
        __DEV__ && console.tron.log(result.kind)
      }
    }),
  }))
  .actions((self) => ({
    create: flow(function* (
      users: { id: number; username: string; public_key: string }[],
      topicName: string,
    ) {
      self.status = "processing"

      const topicApi = new TopicApi(self.environment.api)
      const usernames = users.map((user) => user.username)
      const { encryptedString: encryptedTopicName, keys } = encryptWithSharedKey(users, topicName)

      const result = yield topicApi.create(usernames, encryptedTopicName, keys)

      if (result.kind === "ok") {
        self.status = "idle"

        self.topics.push({
          id: result.id,
          name: topicName,
          users: result.users,
          total: 0,
          delta: 0,
        })
      } else {
        self.status = "error"
      }
    }),
  }))
  .actions((self) => ({
    message: flow(function* (id: number, content: string, parent?: number) {
      self.status = "sending"

      const topicApi = new TopicApi(self.environment.api)
      const topic = self.topics.find((topic) => topic.id == id)
      const { encryptedString: encryptedContent, keys } = encryptWithSharedKey(topic.users, content)

      const result = yield topicApi.message(id, encryptedContent, keys, parent)

      if (result.kind === "ok" && parent) {
        self.status = "idle"
        const message = topic.messages.find((m) => m.id === parent)
        message.answers.push(result)
        message.answers_count += 1
      } else if (result.kind === "ok" && !parent) {
        self.status = "idle"
        topic.messages.push(result)
        topic.total += 1
        topic.unread = 0
        self.fetch(id)
      } else {
        self.status = "error"
      }
      return result
    }),
  }))
  .actions((self) => ({
    answers: flow(function* (messageId: number, topicId: number) {
      self.status = "loading-answers"

      const topicApi = new TopicApi(self.environment.api)
      const result = yield topicApi.answers(messageId)
      const topic = self.topics.find((topic) => topic.id === topicId)
      const message = topic.messages.find((m) => m.id === messageId)

      if (result.length) {
        message.answers = result
      }
      self.status = "idle"
    }),
  }))

type TopicsStoreType = Instance<typeof TopicsStoreModel>
export interface TopicsStore extends TopicsStoreType {}
type TopicsStoreSnapshotType = SnapshotOut<typeof TopicsStoreModel>
export interface TopicsStoreSnapshot extends TopicsStoreSnapshotType {}
export const createTopicsStoreDefaultModel = () => types.optional(TopicsStoreModel, {})
