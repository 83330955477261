import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { Message, TopicResult, TopicResults } from "./api.types"
import { GeneralApiProblem, getGeneralApiProblem } from "./api-problem"
export class TopicApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async create(users: string[], name: string, keys: Map<number, string>): Promise<TopicResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post("/api/topics/create", {
        name,
        users,
        keys,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", ...response.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async message(
    topic_id: number,
    content: string,
    keys: any,
    parent?: number,
    answers_count?: number,
    unread_answers_count?: number,
  ): Promise<TopicResult> {
    try {
      const request = {
        topic_id,
        content,
        keys,
      }
      if (parent) {
        request["parent_id"] = parent
      }
      const response: ApiResponse<any> = await this.api.apisauce.post(
        "/api/topics/messages",
        request,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", ...response.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async list(): Promise<TopicResults> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/api/topics")

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", topics: response.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async fetch(id: number): Promise<TopicResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/api/topics/" + id)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", ...response.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async info(id: number): Promise<object> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/api/topics/" + id + "/info")

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", ...response.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async answers(id: number): Promise<Message[] | GeneralApiProblem> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get("/api/messages/" + id)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return response.data
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
