import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import {
  Box,
  Button,
  HStack,
  Icon,
  PresenceTransition,
  ScrollView,
  Text,
  View,
  VStack,
} from "native-base"
import { G, Path } from "react-native-svg"
import { ViewStyle } from "react-native"
import { TopicCard } from "./topic-card"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1, backgroundColor: "#f3f4f6", minHeight: "100%" }

// background-image: linear-gradient(90deg, rgb(253, 186, 116), rgb(232, 121, 249));
export const MessagesScreen: FC<StackScreenProps<NavigatorParamList, "messages">> = observer(
  function MessagesScreen({ navigation }) {
    return (
      <View testID="MessagesScreen" style={FULL}>
        <Box
          flex={1}
          justifyContent="space-between"
          safeAreaTop
          _dark={{
            backgroundColor: "gray.700",
          }}
          _light={{
            backgroundColor: "gray.50",
          }}
        >
          <VStack
            backgroundColor="#6276F9"
            color="#fffe"
            flex={1}
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
          >
            <Text>🚧</Text>
            <Text color="white">WIP</Text>
          </VStack>
          <HStack shadow={4} p={1} backgroundColor="white" justifyContent="space-around">
            <Button
              onPress={() => navigation.replace("home")}
              variant="ghost"
              colorScheme="secondary"
              p="2"
              borderRadius={100}
            >
              <Icon viewBox="0 0 24 24" size="5">
                <G strokeWidth={2} stroke="#666" fill="none">
                  <Path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </G>
              </Icon>
            </Button>

            <Button
              onPress={() => console.log("TODO")}
              variant="ghost"
              colorScheme="secondary"
              p="2"
              borderRadius={100}
            >
              <Icon viewBox="0 0 24 24" size="5">
                <G strokeWidth={2} stroke="#000" fill="none">
                  <Path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                  />
                </G>
              </Icon>
            </Button>
          </HStack>
        </Box>
      </View>
    )
  },
)
