import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  PresenceTransition,
  ScrollView,
  useColorMode,
  VStack,
} from "native-base"
import { useStores } from "../../models"
import { G, Path } from "react-native-svg"
import { SharedElement } from "react-navigation-shared-element"
import { MessageCard } from "../topic/message-card"
import { NewMessage } from "../topic/new-message"

export const ThreadScreen: FC<StackScreenProps<NavigatorParamList, "topic">> = observer(
  function ThreadScreen({ route, navigation }) {
    const { colorMode } = useColorMode()
    const { topicStore } = useStores()
    const { id, name, message } = route.params

    const topic = topicStore.topics?.find((topic) => topic.id === id)
    const mainMessage = topic?.messages.find((m) => m.id === message)

    useEffect(() => {
      topicStore.answers(message, id)
    }, [])

    useEffect(() => {
      // TODO: better mecanism
    }, [topicStore.status])

    return (
      <Box
        safeArea
        flex={1}
        w="100%"
        height="100%"
        mx="auto"
        _light={{ backgroundColor: "warmGray.50" }}
        _dark={{ backgroundColor: "warmGray.800" }}
      >
        <HStack p={2} alignItems="center" justifyContent="space-between" shadow={3} zIndex={10}>
          <Button
            onPress={() => navigation.goBack()}
            borderRadius={100}
            variant="ghost"
            p="2"
            colorScheme="secondary"
          >
            <Icon viewBox="0 0 24 24" size="5">
              <G strokeWidth={1.5} stroke={colorMode === "light" ? "#000d" : "#fffd"} fill="none">
                <Path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </G>
            </Icon>
          </Button>
          <SharedElement id={`item.${id}.name`}>
            <Heading fontSize="16">{name}</Heading>
          </SharedElement>
          <Button
            onPress={() => console.warn("TODO")}
            borderRadius={100}
            variant="ghost"
            p="2"
            colorScheme="secondary"
          >
            <Icon viewBox="0 0 24 24" size="5">
              <G strokeWidth={1.5} stroke={colorMode === "light" ? "#000d" : "#fffd"} fill="none">
                <Path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </G>
            </Icon>
          </Button>
        </HStack>
        {topic !== undefined && (
          <ScrollView h={100}>
            <PresenceTransition
              visible={true}
              initial={{
                opacity: 0,
                translateY: 64,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                transition: {
                  duration: 350,
                },
              }}
            >
              <VStack p={2} flexGrow={1} justifyContent="flex-end">
                <MessageCard key={mainMessage.id} message={mainMessage} />
                {mainMessage.answers.map((answer) => {
                  return <MessageCard key={answer.id} message={answer} />
                })}
              </VStack>
            </PresenceTransition>
          </ScrollView>
        )}
        <NewMessage topic={topic} message={message} />
      </Box>
    )
  },
)
