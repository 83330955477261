import { Dimensions, StyleSheet, Platform } from "react-native"

export default StyleSheet.create({
  autolink: {
    color: "blue",
  },
  blockQuoteText: {
    color: "grey",
  },
  blockQuoteSection: {
    flexDirection: "row",
  },
  blockQuoteSectionBar: {
    width: 3,
    height: null,
    backgroundColor: "#DDDDDD",
    marginRight: 15,
  },
  bgImage: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bgImageView: {
    flex: 1,
    overflow: "hidden",
  },
  view: {
    alignSelf: "stretch",
  },
  codeBlock: {
    fontFamily: Platform.OS === "ios" ? "Courier" : "Monospace",
    fontWeight: "500",
    backgroundColor: "#DDDDDD",
  },
  del: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  em: {
    fontStyle: "italic",
  },
  heading: {
    fontWeight: "200",
  },
  heading1: {
    fontSize: 32,
  },
  heading2: {
    fontSize: 24,
  },
  heading3: {
    fontSize: 18,
  },
  heading4: {
    fontSize: 16,
  },
  heading5: {
    fontSize: 13,
  },
  heading6: {
    fontSize: 11,
  },
  hr: {
    backgroundColor: "#cccccc",
    height: 1,
  },
  image: {
    height: 200, // Image maximum height
    width: Dimensions.get("window").width - 30, // Width based on the window width
    alignSelf: "center",
    resizeMode: "contain", // The image will scale uniformly (maintaining aspect ratio)
  },
  imageBox: {
    flex: 1,
    resizeMode: "contain",
  },
  inlineCode: {
    backgroundColor: "#eeeeee",
    borderColor: "#dddddd",
    borderRadius: 3,
    borderWidth: 1,
    fontFamily: Platform.OS === "ios" ? "Courier" : "Monospace",
    fontWeight: "bold",
  },
  list: {},
  sublist: {
    paddingLeft: 20,
    width: Dimensions.get("window").width - 60,
  },
  listItem: {
    flexDirection: "row",
  },
  listItemText: {
    flex: 1,
  },
  listItemBullet: {
    fontSize: 20,
    lineHeight: 20,
  },
  listItemNumber: {
    fontWeight: "bold",
  },
  listRow: {
    flexDirection: "row",
  },
  paragraph: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  paragraphWithImage: {
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  strong: {
    fontWeight: "bold",
  },
  table: {
    borderWidth: 1,
    borderColor: "#222222",
    borderRadius: 3,
  },
  tableHeader: {
    backgroundColor: "#222222",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  tableHeaderCell: {
    color: "#ffffff",
    fontWeight: "bold",
    padding: 5,
  },
  tableRow: {
    //borderBottomWidth: 1,
    borderColor: "#222222",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  tableRowLast: {
    borderColor: "transparent",
  },
  tableRowCell: {
    padding: 5,
  },
  text: {
    color: "#222222",
    flexWrap: "nowrap",
  },
  textRow: {
    flexDirection: "row",
  },
  u: {
    borderColor: "#222222",
    borderBottomWidth: 1,
  },
})
