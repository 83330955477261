import { API_URL } from "react-native-dotenv"
console.log("API_URL", API_URL)

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  // url: "API_URL",
  // url: "http://localhost:8000",
  url: "https://correspondance.gourgoulhon.fr/",
  timeout: 10000,
}
