import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { NavigatorParamList } from "../../navigators"
import {
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  Text,
  useColorMode,
  VStack,
} from "native-base"
import { color } from "../../theme/color"
import { useStores } from "../../models"
import { StackScreenProps } from "@react-navigation/stack"
import { useForm, Controller } from "react-hook-form"
import { NativeSyntheticEvent, TextInputKeyPressEventData } from "react-native"

export const LoginScreen: FC<StackScreenProps<NavigatorParamList, "login">> = observer(
  function LoginScreen({ navigation }) {
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm()

    const { userStore } = useStores()

    const onSubmit = (values) => {
      userStore.login(values.username, values.password)
    }

    const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      event.nativeEvent.key === "Enter" && handleSubmit(onSubmit)()
    }

    useEffect(() => {
      if (userStore.token && userStore.status === "idle") {
        navigation.replace("home")
      }
    }, [userStore.token, userStore.status])

    return (
      <Center safeArea flex={1} p="2" py="8" w="100%" mx="auto">
        <VStack w="100%" maxW="450" space={3} mt="5">
          <Heading mt="1" mb="1" fontWeight="medium" size="xs">
            Log in to continue! {userStore.environment.api.apisauce.getBaseURL()}
          </Heading>
          <VStack space={8}>
            <FormControl isRequired isInvalid={"username" in errors}>
              <FormControl.Label>Username</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onBlur={onBlur}
                    placeholder="bright-blue-turtle"
                    onKeyPress={handleKeyPress}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="username"
                rules={{ required: "Username is required" }}
                defaultValue=""
              />
              <FormControl.ErrorMessage>{errors.username?.message}</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={"password" in errors}>
              <FormControl.Label>Password</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onBlur={onBlur}
                    onKeyPress={handleKeyPress}
                    onChangeText={onChange}
                    value={value}
                    type="password"
                    onSubmitEditing={(_) => handleSubmit(onSubmit)()}
                  />
                )}
                name="password"
                rules={{ required: "Password is required" }}
                defaultValue=""
              />
              <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>
            </FormControl>

            <Button
              onPress={handleSubmit(onSubmit)}
              mt="2"
              isLoading={userStore.status === "loading"}
              isLoadingText="Logging in..."
            >
              Log in
            </Button>

            {userStore.status === "error" && <Text color={color.error}>Login failed.</Text>}
            <Button onPress={() => navigation.replace("register")} variant="link">
              Need an account? Register instead.
            </Button>
          </VStack>
        </VStack>
      </Center>
    )
  },
)
