import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import { Button, Center, HStack, Icon, Text, View, VStack } from "native-base"
import { useStores } from "../../models"
import { G, Path } from "react-native-svg"
import { Matrix } from "../home/matrix"

export const CreateTopicValidateScreen: FC<StackScreenProps<NavigatorParamList, "topic/create">> =
  observer(function CreateTopicValidateScreen({ route, navigation }) {
    const { topicStore, userStore } = useStores()

    const handleValidate = async () => {
      topicStore.create(route.params.users, route.params.name)
    }

    const otherUser = route.params.users.find((user) => user.id !== userStore.id)

    useEffect(() => {
      if (topicStore.status === "idle") {
        navigation.goBack()
      }
    }, [topicStore.status])

    return (
      <View flex={1} p="3" w="100%" mx="auto">
        <HStack direction="row-reverse" ml="auto">
          <Button
            onPress={() => topicStore.setStatus("idle")}
            borderRadius={100}
            variant="ghost"
            p="2"
            colorScheme="secondary"
          >
            <Icon viewBox="0 0 24 24" size="6" marginLeft="auto">
              <G strokeWidth={1.5} stroke="#000d" fill="none">
                <Path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </G>
            </Icon>
          </Button>
        </HStack>
        <VStack flexGrow={1}>
          <Center flexGrow={1} w="100%" maxW={420} mx="auto" mt="5">
            <Matrix text={otherUser.public_key} color="#333" timeout={0} />
            <Text maxW={48}>{otherUser.public_key}</Text>
          </Center>
          <Button w="100%" onPress={() => handleValidate()}>
            Validate
          </Button>
        </VStack>
      </View>
    )
  })
