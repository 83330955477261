import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { navigate } from "../../navigators"
import { UserApi } from "../../services/api/user-api"
import { withEnvironment } from "../extensions/with-environment"

export const UserStoreModel = types
  .model("UserStore")
  .props({
    id: -1,
    name: "",
    username: "",
    token: "",
    public_key: "",
    private_key: "",
    status: "idle",
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clear: () => {
      self.status = ""
      self.token = ""
    },
  }))
  .actions((self) => ({
    sync: () => {
      self.status = "idle"
      self.environment.api.apisauce.setHeader("Authorization", "Bearer " + self.token)
    },
    authenticate: () => {
      self.clear()
      console.log("🔒️ Authenticate, redirecting to login screen")
      navigate("login")
    },
  }))
  .actions((self) => ({
    register: flow(function* (name: string, username: string, password: string) {
      self.status = "loading"
      const userApi = new UserApi(self.environment.api)
      const result = yield userApi.register(name, username, password)

      if (result.kind === "ok") {
        self.id = result.id
        self.name = result.name
        self.username = result.username
        self.token = result.token
        self.public_key = result.public_key
        self.private_key = JSON.stringify(result.private_key)
        self.sync()
      } else {
        self.status = "error"
        __DEV__ && console.tron.log(result.kind)
      }
    }),
  }))
  .actions((self) => ({
    login: flow(function* (username: string, password: string) {
      self.status = "loading"
      const userApi = new UserApi(self.environment.api)
      const result = yield userApi.login(username, password)

      if (result.kind === "ok") {
        self.id = result.id
        self.name = result.name
        self.username = result.username
        self.token = result.token
        self.public_key = result.public_key
        self.private_key = JSON.stringify(result.private_key)
        self.sync()
      } else {
        self.status = "error"
        __DEV__ && console.tron.log(result.kind)
      }
    }),
  }))
  .actions((self) => ({
    getPublicKey: flow(function* (id: number) {
      const userApi = new UserApi(self.environment.api)
      const result = yield userApi.getPublicKey(id)

      if (result.kind === "ok") {
        return result.public_key
      } else {
        self.status = "error"
        __DEV__ && console.tron.log(result.kind)
      }
    }),
  }))
  .actions((self) => ({
    getByUsername: flow(function* (username: string) {
      const userApi = new UserApi(self.environment.api)
      const result = yield userApi.getByUsername(username)

      if (result.kind === "ok") {
        return result
      } else {
        self.status = "error"
        __DEV__ && console.tron.log(result.kind)
      }
    }),
  }))

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
export const createUserStoreDefaultModel = () => types.optional(UserStoreModel, {})
