import {
  Box,
  Heading,
  HStack,
  Icon,
  Pressable,
  Spinner,
  Text,
  useColorMode,
  View,
  VStack,
} from "native-base"
import React from "react"
import { G, Path } from "react-native-svg"
import { SharedElement } from "react-navigation-shared-element"

export const TopicCard = ({ id, name, delta, total, unread, navigation }) => {
  const { colorMode } = useColorMode()

  return (
    <Pressable
      w="100%"
      _light={{ backgroundColor: "warmGray.100" }}
      _dark={{ backgroundColor: "warmGray.800" }}
      onPress={() => navigation.navigate("topic", { id, name })}
    >
      {({ isHovered, isFocused, isPressed }) => {
        return (
          <Box
            w="100%"
            _dark={{
              backgroundColor: isPressed
                ? "warmGray."
                : isHovered
                ? "warmGray.700"
                : "warmGray.800",
            }}
            _light={{
              backgroundColor: isPressed
                ? "warmGray.100"
                : isHovered
                ? "warmGray.100"
                : "warmGray.50",
            }}
            overflow="hidden"
            p="2"
          >
            <HStack p="2" space={3} alignItems="center">
              <VStack flex="1" pt="1">
                <HStack justifyContent="space-between" flex="1" alignItems="center">
                  <SharedElement id={`item.${id}.name`}>
                    <Heading fontSize="16">{name}</Heading>
                  </SharedElement>
                </HStack>
                <HStack justifyContent="space-between" flex="1" alignItems="center">
                  {unread > 0 ? (
                    <HStack alignItems="center">
                      <View mr={2} size={2} backgroundColor="green.500" rounded={100}></View>
                      <Text bold={true}>{`${unread} new message${unread <= 1 ? "" : "s"}`}</Text>
                    </HStack>
                  ) : (
                    <Text opacity="60">
                      {total === undefined
                        ? "loading info..."
                        : `${total} message${total <= 1 ? "" : "s"}`}
                    </Text>
                  )}
                  <HStack alignItems="center" justifyContent="center" ml="auto">
                    {delta === undefined ? (
                      <Spinner size="sm" accessibilityLabel="Loading info" />
                    ) : (
                      <>
                        <Text opacity={0.8} fontSize="14" mr="1">
                          {delta > 0 && "+"}
                          {delta}
                        </Text>
                        <Icon maxW="5" maxH="5" viewBox="0 0 24 24" pb={0.5}>
                          <G
                            strokeWidth={1.5}
                            opacity={0.8}
                            stroke={colorMode === "light" ? "#000" : "#fff"}
                            fill="none"
                          >
                            {delta < 0 ? (
                              <Path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                              />
                            ) : (
                              <Path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                              />
                            )}
                          </G>
                        </Icon>
                      </>
                    )}
                  </HStack>
                </HStack>
              </VStack>
            </HStack>
          </Box>
        )
      }}
    </Pressable>
  )
}
