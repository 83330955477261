import { Button, HStack, Icon, TextArea } from "native-base"
import React, { useEffect, useState } from "react"
import { G, Path } from "react-native-svg"
import { useStores } from "../../models"
import { Topic } from "../../models/topic-store/topic-store"

export const NewMessage = ({ topic, message }: { topic: Topic | undefined; message?: number }) => {
  const [value, setValue] = useState("")

  const { topicStore } = useStores()

  const sendMessage = () => {
    if (value.length > 0) {
      topicStore.setStatus("sending")
    }
  }

  useEffect(() => {
    setValue("")
  }, [topic?.messages.length])

  useEffect(() => {
    switch (topicStore.status) {
      case "sending":
        if (value) {
          topicStore.message(topic.id, value, message)
        }
        break
      default: // "idle"
        setValue("")
    }
  }, [topicStore.status])

  const handleChange = (text) => {
    setValue(text)
  }

  return (
    <HStack
      alignItems="center"
      style={{
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: -1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 2,
      }}
      p={2}
      mb={2}
    >
      <TextArea autoCompleteType={""} flexGrow={1} value={value} onChangeText={handleChange} />
      <Button
        ml={2}
        onPress={() => sendMessage()}
        borderRadius={100}
        p="2"
        isLoading={topicStore.status === "sending"}
      >
        <Icon viewBox="0 0 24 24" size="5">
          <G strokeWidth={1.5} stroke="#fffd" fill="none" transform="rotate(90 12 12)">
            <Path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
            />
          </G>
        </Icon>
      </Button>
    </HStack>
  )
}
