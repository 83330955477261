import { types } from "mobx-state-tree"

/**
 * User model.
 */
export const User = types.model("User").props({
  id: types.identifierNumber,
  name: types.string,
  username: types.string,
  public_key: types.string,
})
