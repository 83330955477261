import { ApisauceInstance, create } from "apisauce"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"
import { Toast } from "native-base"
import { UserStore } from "../../models/user-store/user-store"

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * User / authentication store
   * TODO to remove -> useStores() instead?
   */
  userStore: UserStore

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    })

    // TODO: only if dev/debug mode
    this.apisauce.addMonitor(async (response) => {
      console.log(
        "☎️ Api Call",
        response.config?.method,
        response.config?.baseURL + response.config?.url,
        response,
      )

      if (response.status === 401) {
        Toast.show({
          p: 2,
          borderRadius: 8,
          color: "orange.900",
          backgroundColor: "orange.500",
          description: "😵️ secure token expired!",
        })
        this.userStore.authenticate()
      }
    })
  }
}
