import React, { Component } from "react"
import { View } from "react-native"
import SimpleMarkdown from "simple-markdown"
import styles from "./styles"
import { Rules } from "./rules"

class Markdown extends Component {
  constructor(props) {
    super(props)
    // if (props.enableLightBox && !props.navigator) {
    //   throw new Error("props.navigator must be specified when enabling lightbox")
    // }

    const opts = {
      enableLightBox: props.enableLightBox,
      navigator: props.navigator,
      imageParam: props.imageParam,
      onLink: props.onLink,
      bgImage: props.bgImage,
      onImageOpen: props.onImageOpen,
      onImageClose: props.onImageClose,
      rules: props.rules,
    }

    const mergedStyles = { ...styles, ...(props.styles ?? {}) }
    var rules = Rules(mergedStyles, opts)
    rules = { ...SimpleMarkdown.defaultRules, ...rules, ...opts.rules }

    // const parser = SimpleMarkdown.parserFor(SimpleMarkdown.defaultRules);
    const parser = SimpleMarkdown.parserFor(rules)
    this.parse = function (source) {
      const blockSource = source + "\n\n"
      return parser(blockSource, { inline: false })
    }
    this.renderer = SimpleMarkdown.outputFor(rules, "react")
  }

  componentDidMount() {
    if (this.props.onLoad) {
      this.props.onLoad()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(nextProps.children) !== JSON.stringify(this.props.children)
  }

  render() {
    const child = Array.isArray(this.props.children)
      ? this.props.children.join("")
      : this.props.children

    const tree = this.parse(child)

    return <View style={[styles.view, this.props.styles?.view]}>{this.renderer(tree)}</View>
  }
}

export default Markdown
