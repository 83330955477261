import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { StackScreenProps } from "@react-navigation/stack"
import { NavigatorParamList } from "../../navigators"
import {
  Box,
  Button,
  HStack,
  Icon,
  PresenceTransition,
  ScrollView,
  Text,
  useColorMode,
  View,
  VStack,
} from "native-base"
import { G, Path } from "react-native-svg"
import { ViewStyle } from "react-native"
import { TopicCard } from "./topic-card"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1, backgroundColor: "#f3f4f6", minHeight: "100%" }

// background-image: linear-gradient(90deg, rgb(253, 186, 116), rgb(232, 121, 249));
export const HomeScreen: FC<StackScreenProps<NavigatorParamList, "home">> = observer(
  function HomeScreen({ navigation }) {
    const { topicStore } = useStores()
    const { colorMode } = useColorMode()

    // useEffect(() => {
    //   // topicStore.setStatus("loading")
    // }, [])
    useEffect(() => {
      if (topicStore.status === "creating") {
        navigation.navigate("topic/create")
      }
      if (topicStore.status === "loading") {
        // topicStore.load() // TODO
      }
    }, [topicStore.status])

    return (
      <View testID="HomeScreen" style={FULL}>
        <Box
          flex={1}
          justifyContent="space-between"
          safeAreaTop
          _dark={{
            backgroundColor: "warmGray.800",
          }}
          _light={{
            backgroundColor: "warmGray.50",
          }}
        >
          <ScrollView mt="auto">
            <VStack
              _light={{
                backgroundColor: "#6276F9",
              }}
              _dark={{
                backgroundColor: "#57534e",
              }}
              // color="#fffe"
            >
              <VStack p="4">
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack alignItems="center">
                    <Button
                      onPress={() => navigation.navigate("info")}
                      borderRadius={100}
                      variant="ghost"
                      p="2"
                      colorScheme="secondary"
                    >
                      <Icon viewBox="0 0 24 24" size="5">
                        <G strokeWidth={1.5} stroke="#fffd" fill="none">
                          <Path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </G>
                      </Icon>
                    </Button>
                  </HStack>
                  <HStack>
                    <Button
                      onPress={() => topicStore.setStatus("creating")}
                      variant="ghost"
                      colorScheme="secondary"
                      p="2"
                      borderRadius={100}
                    >
                      <Icon viewBox="0 0 24 24" size="5">
                        <G strokeWidth={2} stroke="white" fill="none">
                          <Path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                        </G>
                      </Icon>
                    </Button>
                    {/* <Button backgroundColor="none" variant="subtle" p="2">
                      <Icon viewBox="0 0 24 24" maxW="5" maxH="5">
                        <G strokeWidth={1.5} stroke="#0008" fill="none">
                          <Path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                          />
                        </G>
                      </Icon>
                    </Button> */}
                  </HStack>
                </HStack>
                {/* <Matrix
                  text={Math.floor(Math.random() * 100000000000000000000)}
                  mt="4"
                  color="#fffd"
                  fontWeight="semibold"
                  textAlign="center"
                /> */}
              </VStack>
              <PresenceTransition
                visible={true}
                initial={{
                  opacity: 0,
                  translateY: 64,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  transition: {
                    duration: 350,
                  },
                }}
              >
                <VStack
                  flex="1"
                  w="100%"
                  alignItems="center"
                  borderTopRadius="2xl"
                  overflow="hidden"
                  mt="2"
                  pb="9"
                  _light={{ backgroundColor: "warmGray.50" }}
                  _dark={{ backgroundColor: "warmGray.800" }}
                >
                  {topicStore.topics.length === 0 && (
                    <Box w="100%" textAlign="center" p="10">
                      <Text opacity={0.4}>Nothing for now.</Text>
                    </Box>
                  )}
                  {topicStore.topics.map((topic) => {
                    return (
                      <TopicCard
                        key={topic.id}
                        id={topic.id}
                        name={topic.name}
                        total={topic.total}
                        delta={topic.delta}
                        unread={topic.unread}
                        navigation={navigation}
                      />
                    )
                  })}
                </VStack>
              </PresenceTransition>
            </VStack>
          </ScrollView>
          <HStack
            shadow={4}
            p={1}
            _light={{ backgroundColor: "white" }}
            _dark={{ backgroundColor: "warmGray.900" }}
            justifyContent="space-around"
          >
            <Button
              onPress={() => topicStore.load()}
              variant="ghost"
              colorScheme="secondary"
              p="2"
              borderRadius={100}
              isLoading={topicStore.status === "loading"}
              _dark={{}}
            >
              <Icon viewBox="0 0 24 24" size="5">
                <G strokeWidth={2} stroke={colorMode === "light" ? "#000" : "#fff"} fill="none">
                  <Path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </G>
              </Icon>
            </Button>

            <Button
              onPress={() => navigation.replace("messages")}
              variant="ghost"
              colorScheme="secondary"
              p="2"
              borderRadius={100}
            >
              <Icon viewBox="0 0 24 24" size="5">
                <G strokeWidth={2} stroke="#666" fill="none">
                  <Path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                  />
                </G>
              </Icon>
            </Button>
          </HStack>
        </Box>
      </View>
    )
  },
)
